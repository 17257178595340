<template>
  <div>
    <div class="headwrap">
      <el-pagination
        :page-size="count"
        :current-page="page"
        :page-count="9"
        layout="total, prev, pager, next"
        :total="total"
        hide-on-single-page
        @current-change="handleLiveInfoChange"
      ></el-pagination>
      <div style="margin-left:20px">平均分：{{commentRate}} 分</div>
    </div>
    <div v-for="(item,index) in comments" :key="index" class="commentbox">
      <div>
        <span>{{item.nickName}}</span>
        <span style="margin: 0 30px">{{item.rate}}分</span>
        <span>{{item.genTime}}</span>
      </div>
      <div style="margin:20px 0">{{item.comment}}</div>
      <div>
        <img
          v-for="(img,index) in item.imgs"
          :key="index+'a'"
          :src="img"
          alt
          class="imgbox"
          @click="previewImg(img)"
        />
      </div>
      <div v-if="item.child.length>0">
        <div v-for="(el,index) in item.child" :key="index+'b'" class="childbox">
          <div>
            <span v-if="el.replyType">商家回复</span>
            <span v-else>用户追评</span>
            <span style="margin-left:30px">{{el.genTime}}</span>
          </div>
          <div style="margin-top:10px">{{el.comment}}</div>
        </div>
      </div>

      <div class="childbox">
        <el-button type="primary" size="small" @click="replyCommentPcFn(item.id)">回复</el-button>
      </div>
    </div>

    <!-- 图片预览 -->
    <el-dialog :visible.sync="showImg" top="2vh" destroy-on-close>
      <img :src="imgForPreview" width="100%" alt />
    </el-dialog>
  </div>
</template>

<script>
import { getCommentsPc, replyCommentPc } from "@/api/api";
export default {
  data() {
    return {
      imgForPreview: "",
      text: "",
      imgs: [],
      comments: [],
      commentRate: 0,
      percentage: 0,
      total: 0,
      page: 1,
      count: 15,
      showImg: false
    };
  },
  mounted() {
    this.getCommentsPcFn();
  },
  computed: {
    biz() {
      return this.$store.getters.getBiz;
    }
  },
  methods: {
    getCommentsPcFn() {
      const that = this;
      getCommentsPc({
        page: that.page,
        count: that.count
      }).then(res => {
        if (res.result) {
          this.comments = res.data.comments;
          that.total = res.data.total;
          that.commentRate = res.data.commentRate;
        }
      });
    },
    replyCommentPcFn(id) {
      const that = this;
      this.$prompt("请输入回复", {
        center: true,
        inputType: "textarea",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入回复内容"
      })
        .then(({ value }) => {
          replyCommentPc({
            id: id,
            comment: value
          }).then(res => {
            if (res.result) {
              that.getCommentsPcFn();
            } else {
              this.$alert(res.message, {
                type: "warning",
                callBack: () => {}
              });
            }
          });
        })
        .catch(() => {
          this.$message.warning("取消回复");
        });
    },
    // 实时信息分页点击
    handleLiveInfoChange(val) {
      //分页的点击事件
      this.page = val;
      this.getCommentsPcFn();
    },
    //预览图片
    previewImg(item) {
      this.imgForPreview = item.replace(".thumb", "");
      this.showImg = true;
    }
  }
};
</script>

<style scoped>
.headwrap {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px dashed #aaa;
}
.commentbox {
  box-shadow: 0 0 10px #aaa;
  border-radius: 10px;
  margin: 20px 10px;
  padding: 20px;
  width: 30%;
}
.childbox {
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  color: #777;
  font-size: 13px;
}

.imgbox {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  position: relative;
}
</style>