import { render, staticRenderFns } from "./CommentSight.vue?vue&type=template&id=353f3463&scoped=true&"
import script from "./CommentSight.vue?vue&type=script&lang=js&"
export * from "./CommentSight.vue?vue&type=script&lang=js&"
import style0 from "./CommentSight.vue?vue&type=style&index=0&id=353f3463&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "353f3463",
  null
  
)

export default component.exports