<template>
  <div style="padding: 20px 40px">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="评论" name="0">
        <comment />
      </el-tab-pane>
      <el-tab-pane label="官方实时信息" name="1">
        <div v-if="addInfoShow" class="addbox">
          <el-form label-width="100px" hide-required-asterisk size="small">
            <el-form-item label="内容" prop="name">
              <el-input v-model="text" type="textarea" autosize placeholder="必填"></el-input>
            </el-form-item>
            <el-form-item label="上传图片">
              <el-upload
                action="https://server.solar960.com/api/fileUploadWithThumbBiz"
                :headers="headerObj"
                :multiple="false"
                :limit="20"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :show-file-list="false"
                :on-progress="uploadProgress"
                :on-error="uploadError"
              >
                <el-button
                  type="primary"
                  size="mini"
                  :disabled="percentage>0||imgs.length==20"
                >点击上传图片</el-button>
                <span
                  v-if="imgs.length==20"
                  style="color:red;margin-left:20px;font-size:12px"
                >已到图片数量上限</span>
              </el-upload>
            </el-form-item>
            <div class="imglistbox" style="margin:30px">
              <div v-for="(pic,index) in imgs" :key="index" class="imgwrap">
                <img :src="baseurl+pic" alt class="imgwrap" @click="previewImg(baseurl+pic)" />
                <div class="imgdeletewrap" @click="deleteFileBizFn(pic)">
                  <i class="el-icon-delete-solid"></i>
                </div>
              </div>
              <div v-if="percentage>0" class="imgwrap">
                <el-progress type="circle" :percentage="percentage"></el-progress>
              </div>
            </div>
            <el-form-item>
              <el-button type="danger" size="small" @click="cancelAddInfo">取消</el-button>
              <el-button type="primary" size="small" @click="saveLiveInfo">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div style="margin:20px 0" v-else>
          <el-button type="primary" size="small" @click="addInfoShow=true">发布实时信息</el-button>
        </div>
        <table>
          <thead>
            <td>发布人</td>
            <td>发布时间</td>
            <td>内容</td>
            <td>图片</td>
            <td>操作</td>
          </thead>
          <tr v-for="(item,index) in liveInfo" :key="index">
            <td width="10%">{{item.nickName}}</td>
            <td width="15%">{{item.genTime}}</td>
            <td width="30%">{{item.text}}</td>
            <td>
              <img
                v-for="(img,index) in item.imgs"
                :key="index+'a'"
                :src="img"
                alt
                class="imgbox"
                @click="previewImg(img)"
              />
            </td>
            <td width="10%">
              <el-button type="danger" size="mini" plain @click="deleteSightLiveInfo(item)">删除</el-button>
            </td>
          </tr>
        </table>
        <div class="paginationwrap-g" v-if="activeName>0">
      <el-pagination
        :page-size="count"
        :current-page.sync="page"
        layout="total, prev, pager, next"
        :total="total"
        hide-on-single-page
        @current-change="handleLiveInfoChange"
      ></el-pagination>
    </div>
      </el-tab-pane>
      <el-tab-pane label="网友实时信息" name="2">
        <table>
          <thead>
            <td>发布人</td>
            <td>时间</td>
            <td>内容</td>
            <td>图片</td>
          </thead>
          <tr v-for="(item,index) in liveInfo" :key="index">
            <td width="10%">{{item.nickName}}</td>
            <td width="15%">{{item.genTime}}</td>
            <td width="40%">{{item.text}}</td>
            <td>
              <img
                v-for="(img,index) in item.imgs"
                :key="index+'a'"
                :src="img"
                alt
                class="imgbox"
                @click="previewImg(img)"
              />
            </td>
          </tr>
        </table>
        <div class="paginationwrap-g" v-if="activeName>0">
      <el-pagination
        :page-size="count"
        :current-page.sync="page"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleLiveInfoChange"
      ></el-pagination>
    </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 图片预览 -->
    <el-dialog :visible.sync="showImg" top="2vh" destroy-on-close>
      <img :src="imgForPreview" width="100%" alt />
    </el-dialog>
  </div>
</template>

<script>
import {
  getSightLiveInfoPC,
  deleteSightLiveInfoPc,
  deleteFileBiz,
  addSightLiveInfoPc,
} from "@/api/api";
import comment from "../components/Comment.vue";
export default {
  components: {
    comment
  },
  data() {
    return {
      activeName: "0",
      baseurl: "https://server.solar960.com",
      headerObj: {
        Accept: "application/x.server.v4+json",
        Authorization: localStorage.getItem("Authorization")
      },
      liveInfo: [],
      total: 0,
      page: 1,
      count: 12,
      showImg: false,
      imgForPreview: "",
      percentage: 0,
      addInfoShow: false,
      text: "",
      imgs: [],
    };
  },
  mounted() {
    this.getSightLiveInfo();
  },
  computed: {
    sightCompany() {
      return this.$store.getters.getBiz;
    }
  },
  methods: {
    // 获取实时信息
    getSightLiveInfo() {
      const that = this;
      getSightLiveInfoPC({
        id: that.sightCompany.idSight,
        page: that.page,
        count: that.count,
        isOfficial: that.activeName
      }).then(res => {
        if (res.result) {
          that.liveInfo = res.data.liveInfo;
          that.total = res.data.total;
        }
      });
    },

    // 删除实时信息
    deleteSightLiveInfo(id) {
      const that = this;
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteSightLiveInfoPc({
            id: id
          }).then(res => {
            if (res.result) {
              that.getSightLiveInfo();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },

    saveLiveInfo() {
      const that = this;
      if (!that.text) {
        this.$alert("内容不能为空", {
          type: "warning",
          callBack: () => {}
        });
      } else {
        addSightLiveInfoPc({
          idSight: that.sightCompany.idSight,
          text: that.text,
          imgs: that.imgs
        }).then(res => {
          if (res.result) {
            this.$message.success("添加成功");
            that.text = "";
            that.imgs = [];
            that.addInfoShow = false;
            that.getSightLiveInfo();
          } else {
            this.$alert(res.message, {
              type: "warning",
              callBack: () => {}
            });
          }
        });
      }
    },

    // 实时信息分页点击
    handleLiveInfoChange(val) {
      //分页的点击事件
      this.page = val;
      this.getSightLiveInfoPC();
    },
    //预览图片
    previewImg(item) {
      this.imgForPreview = item.replace(".thumb", "");
      this.showImg = true;
    },
    //删除图片
    deleteFileBizFn(file) {
      const that = this;
      file = file.replace("https://server.solar960.com", "");
      deleteFileBiz({
        file: file
      }).then(res => {
        if (res.result) {
          let index = that.imgs.findIndex(item => {
            return item == file;
          });
          that.imgs.splice(index, 1);
        }
      });
    },
    //上传图片成功
    handleAvatarSuccess(res) {
      if (res.result) {
        let imgUrl = res.data.files;
        this.imgs.push(imgUrl);
      } else {
        this.$message.error(res.message);
      }
      this.percentage = 0;
    },
    //图片上传检查
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },

    //上传进度
    uploadProgress(event) {
      this.percentage = parseInt(event.percent);
    },

    //上传图片错误
    uploadError() {
      this.$message({
        message: "上传失败",
        type: "danger"
      });
      this.percentage = 0;
    },
    //取消添加位置点
    cancelAddInfo() {
      if (this.imgs.length > 0) {
        this.imgs.forEach(element => {
          this.deleteFileBizFn(element);
        });
      }
      this.addInfoShow = false;
    }
  }
};
</script>

<style scoped>
.commentbox {
  box-shadow: 0 0 10px #aaa;
  border-radius: 10px;
  margin: 20px 10px;
  padding: 20px;
  width: 30%;
}
.childbox {
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  color: #777;
  font-size: 13px;
}

.imgbox {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  position: relative;
}

.imgwrap {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 120px;
  height: 120px;
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 40px;
}
table thead td {
  font-weight: bold;
  color: #666;
  border-bottom: 1px solid #555;
  padding-bottom: 5px;
}
table tr td {
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  font-size: 13px;
}
.imglistbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 70px;
}
.imgdeletewrap {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
  background: #fff;
  padding: 2px 4px;
  border-radius: 100%;
}
.addbox {
  padding: 40px;
  box-shadow: 0 0 10px #aaa;
  margin: 30px;
  border-radius: 10px;
}
</style>